<template>
	<span>{{time}}</span>
</template>

<script>
	const moment = require('moment');
	export default {
		name: "CSTime",
		props: {
			data: {
				type:[String, Date],
				default:''
			}
		},
		computed: {
			time(){
				if(this.data){
					try{
                		return moment(this.data).fromNow() // eslint-disable-line
                    }catch(e){
                    	return 'no-time'
                    }
                } else 'no-time'
            },
        }
    };
</script>

<style lang="scss" scoped>

</style>
